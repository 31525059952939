import React from "react";

import Accounts from "../../assets/icons/Wallets.png";
import imgData from "../../assets/products/Teasury.png";
import { CustomButton } from "../CustomButton/CustomButton";

const Treasury = () => {
  return (
    <div className="">
      <div className="mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl p-2">
        <div>
          <img className="shadow-xl w-full h-full lg:h-96 object-cover object-top" src={imgData} />
        </div>

        <div className=" mt-5 lg:mt-10">
          <div className="">
            {/* <img src={Accounts} className="w-16" /> */}
            <h3 className="lg:text-4xl text-2xl font-bold text-deep-purple-900">
              Teasury
            </h3>
            <h4 className="text-2xl text-orange font-semibold">
              Run your business with TOTAL FINANCIAL CLARITY
            </h4>
          </div>

          <span className="relative flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
          </span>

          <div className="mt-5">
            <h3 className="text-base lg:text-lg ">
              How many banks do you bank with? How many accounts across your
              family trusts, your holding company and your subsidiaries? How
              many sources to collect AR and AP information? Now imagine all of
              that information with real-time forecasts in one place. Imagine
              the time saved. Imaging efficiencies achieved. Imagine the clarity
              with which you can operate your business. We return 60% savings in
              accounting time alongwith substantial cost savings.
            </h3>

            <h3 className="text-base lg:text-lg mt-10">
              Our virtual accounts integrate with our treasury management system
              under our parent company Flybridge, which integrates all your
              banks, bank accounts, virtual accounts and associated transaction
              data in one place. It also integrates with your ERP. Meaning, when
              you login every morning, you have clear visibility of your cash
              flows – wherever you login from!
            </h3>
          </div>
        </div>
        <CustomButton
          text="Talk to OUR expert &gt;"
          color="text-white px-8"
          borderColor="border-orange"
          fill="bg-orange"
          padding="p-2 mt-16"
          href="/contact"
        />
      </div>
    </div>
  );
};

export default Treasury;
