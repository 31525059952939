import { Link } from "react-router-dom";

export const CustomButton = ({
  text,
  color,
  bgColor,
  borderColor,
  fill,
  padding,
  href,
}) => {
  return (
    <Link to={href}>
      <button
        className={`${color} ${bgColor} ${fill} ${borderColor} ${padding} text-sm uppercase border-2 px-16 lg:w-[18vw] w-full`}
      >
        {text}
      </button>
    </Link>
  );
};

