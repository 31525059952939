import React from "react";

import B2B from "../../assets/products/B2B.png";
import { CustomButton } from "../CustomButton/CustomButton";



const B2B_Page = () => {
  return (
    <div className="">
      <div className="mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl p-2">
        <div>
          <img className="shadow-xl w-screen h-full lg:h-96 object-cover" src={B2B} />
        </div>

        <div className=" mt-5 lg:mt-10">
          <div className=" flex items-center">
            {/* <img src={logoWallet} className="w-16" /> */}
            <div className="">
              <h3 className="lg:text-4xl text-2xl font-bold text-deep-purple-900">
                B2B payments
              </h3>
              <h4 className="text-2xl text-orange font-semibold">
                Make and reconcile supply chain payments real-time
              </h4>
            </div>
          </div>

          <span className="relative flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
          </span>

          <div className="mt-5">
            <h3 className="text-base lg:text-lg ">
              Payment Gateway – Integrate payments with ease on your website,
              your POS device or your EDC devices, with some of the best rates
              in the market. Secure electronic payment processing. We also
              provide a range of POS and EDC devices.
            </h3>

            <h3 className="text-base lg:text-lg mt-10">
              Merchant Management System – Manage payments, settlements,
              reconciliation at the most competitive rates available in
              Thailand. Have full visibility of all transactions, manage refunds
              and settlements with ease.
            </h3>

            <h3 className="text-base lg:text-lg mt-10">
              Virtual Accounts – Pay directly to other Virtual Accounts within
              IPPS provided ecosystem, or to other banks on a single click.
            </h3>
          </div>
        </div>

        <CustomButton
          text="Talk to OUR expert &gt;"
          color="text-white px-8"
          borderColor="border-orange"
          fill="bg-orange"
          padding="p-2 mt-16"
          href="/contact"
        />
      </div>
    </div>
  );
};

export default B2B_Page;
