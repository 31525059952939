import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";


const DropDownContext = "mb-2 lg:mb-10"
const TitleOpen = "flex text-lg lg:text-2xl text-deep-purple-900 font-bold text-orange "
const TitleClose = "flex text-lg lg:text-2xl text-deep-purple-900 font-bold hover:text-orange"
const TextMain = "text-sm lg:text-lg mt-2 ml-8"
const SubTitle = "text-base lg:text-lg mt-2 font-bold ml-8"
const TextTitleDot = "text-base lg:text-lg mt-2 ml-8"
const TextDot = 'list-disc text-base lg:text-lg ml-8'

// LEFT
const InternationalCollctionAndPayMents = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        International Collections and payments
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          Collect from your Thai customers and pay your Thai suppliers
          without a Thai bank account. Open an account with IPPS. We
          manage the rest. Our customers have reduced cost of
          transactions, made is easy for their customers to pay them,
          with real-time clarity of their collections.
        </h3>
        <div className="">
          <h3 className={SubTitle}>
            Automate
          </h3>
          <h3 className={TextTitleDot}>
            <ul className={TextDot}>
              <li>Supply chain payments</li>
              <li>Payroll,</li>
              <li>Tax deduction</li>
              <li>Payment instructions</li>
              <li>Reconciliation</li>
              <li>Forecasts</li>
              <li>General ledger</li>
            </ul>
          </h3>

          <h3 className={SubTitle}>Reduce</h3>

          <h3 className={TextTitleDot}>
            <ul className={TextDot}>
              <li>Bank fees,</li>
              <li>Operational costs,</li>
              <li>Fraud</li>
            </ul>
          </h3>

          <h3 className={SubTitle}>Achieve</h3>

          <h3 className={TextTitleDot}>
            <ul className={TextDot}>
              <li>Transparency,</li>
              <li>Time savings,</li>
              <li>Cost savings,</li>
              <li>Strategic decision-making capability,</li>
              <li>ESG benefits</li>
            </ul>
          </h3>

        </div>
      </div>
    </div>
  )
}

const TourismAndHospitality = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        Tourism and hospitality
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          Make your customers’ dream holiday sweeter. Provide them
          e-wallets to pay easily within your resorts, integrate with
          the keys, enable payments in the associated ecosystem.
        </h3>

        <h3 className={TextMain}>
          Enable rule based payments to your suppliers whereby money
          automatically gets setup for payments from receivables, and
          you can make a single click approval to clear all your AP.
        </h3>
      </div>
    </div >
  )
}

const FinanceAndLending = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        Finance and lending
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          It is important as a financier/lender to know your money in
          the lendee’s supply chain is secure, and you are the first
          beneficiary of the end customer payments, not your lendee.
        </h3>

        <h3 className={TextMain}>
          Virtual accounts setup with our ecosystem enable you to lend
          money with full visibility of the funds. Rule based
          collections ensure that when the lendee receives the money
          from end customer first, it goes into an escrow with us, you
          get paid and remaining balance goes to the lendee.
        </h3>
      </div>
    </div>
  )
}

const ManufacturingAndAutomotive = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        Manufacturing and automotive
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          Manage seamless payments across your supply chain. Structure
          early/late payments depending on the need of the hour, in
          agreement with your suppliers with in-house banking
          solutions. Setup departmental budgets in designated virtual
          accounts with clear visibility of the cash utilization
          across board, while having an aggregated view at a group
          level. The possibilities of customizing the flow of payments
          with virtual accounts are endless.
        </h3>
      </div>
    </div>
  )
}

const OilAndGas = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        Oil and Gas
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          Exploration fields take you to remote places, and with it,
          you need to support remote, local payments. Leverage our
          payments ecosystem supported with cross border payment
          partners to have a seamless payment ecosystem, with
          structured payments and reconciliation across your supply
          chain.
        </h3>
      </div>
    </div>
  )
}

const RetailAndCostomerGoods = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        Retail and consumer goods
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          Manage rental payments from shop owners in the mall with
          full visibility of transactions as well as your dues.
        </h3>
      </div>
    </div>
  )
}


const ECommerce = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        e-Commerce
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          Integrate with IPPS Payment Gateway and collect payments seamlessly on any of your sales channels, 
          be it your website, application or invoice. Our payment solution can be integrated with your invoices for customers to transfer 
          funds with reference - so you can reconcile payments real-time. Beyond this, you can enable payments across your supply chain,
           with clear visibility of all transactions real-time.
        </h3>
      </div>
    </div>
  )
}

const TransportAndLogistics = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        Transport and Logistics
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          Manage collections for every completed job, real-time. Enable driver wallets to make payroll and miscellaneous payments to your drivers.
           Digitise payments and collections across your ecosystem - right from the truck rental, to garages, to customers in one single ecosystem
        </h3>
      </div>
    </div>
  )
}

// RIGHT
const ForeignWorkerInclusionPayroll = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        Foreign worker inclusion, payroll
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          Every unbanked person can open a wallet account with us, with
          ability to make payments and collect funds and payroll
          straight from and to the wallet. Corporates can make payroll
          to contract workers as well, with managed withholding tax.
        </h3>
      </div>
    </div>
  )
}

const ThaiCorporationsAndFamilyOffices = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        Thai corporations and family offices
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          Simplify cash visibility across banks, accounts, subsidiaries
          and supply chain, with real time:
        </h3>
        <h3 className={TextMain}>
          <ul className={TextDot}>
            <li>Payments</li>
            <li>Settlements,</li>
            <li>Reconciliation</li>
            <li>Cash forecasts</li>
            <li>Reporting</li>
            <li>Accounting</li>
          </ul>
        </h3>
      </div>
    </div>
  )
}

const PLanningANdIPO = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        Planning an IPO
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          Line your ducks in a row! Be ready for audits, financial
          inquiry, and full transparency of your financial records.
          Start with putting the data where it belongs, while bringing
          real-time visibility of your finances to outwit every
          question comping your way.
        </h3>

        <h3 className={TextMain}>
          Simplify cash visibility across banks, accounts,
          subsidiaries and supply chain, with real time:
        </h3>
        <h3 className={TextMain}>
          <ul className={TextDot}>
            <li>Payments</li>
            <li>Settlements,</li>
            <li>Reconciliation</li>
            <li>Cash forecasts</li>
            <li>Reporting</li>
            <li>Accounting</li>
          </ul>
        </h3>
        <h3 className={TextMain}>
          See how we can hold your hands as you become a listed
          corporation in the use case for Thai corporates.
        </h3>
      </div>
    </div>
  )
}

const OpeningAShop_Franchinse = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        Opening a Shop/Franchise
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          Opening a new business can be overwhelming. We can take some
          of the burden away by setting up your subscription based
          payments infrastructure. Use POS and EDC devices provided by
          our parent company, integrated with payment gateway. If you
          are a franchise owner, ensure you get your royalties paid
          first before the franchisee gets paid. Our virtual accounts
          and payment gateway solutions manage all of that for you
          seamlessly.
        </h3>
      </div>
    </div>
  )
}

const RealEstate = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        Real Estate
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          Ensure money is in the escrow before handing the keys - be
          it a seller or an owner renting a condo. Effectively manage
          all associated fees - maintenance, repairs, etc. integrated
          with the application of the real estate management.
        </h3>

        <h3 className={TextMain}>
          Pay contractors seamlessly during construction through
          wallets - send notifications for any major updates, payments
          for daily wages etc. right into the contractor wallet. Run
          an ecosystem of money within your ecosystem.
        </h3>
      </div>
    </div>
  )
}

const HealcareAndPhamaceuticals = () => {
  const [open, setOpen] = useState(false)
  return (
    <div className={DropDownContext}>
      <button type="button" className={open ? TitleOpen : TitleClose} onClick={e => setOpen(!open)}>
        <IoIosArrowForward className={open ? " rotate-90 m-1" : "m-1"} />
        Healthcare and Pharmaceuticals
      </button>
      <div className={open ? "" : "hidden"}>
        <h3 className={TextMain}>
          Hospitals have permanent issues of queues at payment
          counters. Imagine having a hospital wallet where the patient
          can pay from. Imagine admitted patients being able to
          procure any ongoing services, procedures or even food within
          the hospital with the same wallet. Hospital staff and
          patients using the hospital wallet in the canteens and
          restaurants. Full visibility of earnings at canteens and
          restaurants to ensure the hospital is paid the rental
          percentage in full. All with a simple closed loop wallet,
          which can be white labeled for the hospital!
        </h3>

        <h3 className={TextMain}>
          Pharmaceutical companies can manage their entire supply
          chain payments. Structure early/late payments depending on
          the need of the hour, in agreement with your suppliers with
          in-house banking solutions. Setup departmental budgets in
          designated virtual accounts with clear visibility of the
          cash utilization across board, while having an aggregated
          view at a group level. The possibilities of customizing the
          flow of payments with virtual accounts are endless.
        </h3>
      </div>
    </div>
  )
}


const Usecases = () => {
  return (
    <div className="">
      <div className="mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl p-2">

        <div className=" mt-5 lg:mt-10">
          <div className=" flex  justify-center py-1 lg:py-4">
            <h3 className="lg:text-2xl text-2xl font-bold text-deep-purple-900 text-center uppercase">
              Use Cases
            </h3>
          </div>

          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mt-10">
            {/* LEFT */}
            <div>
              <InternationalCollctionAndPayMents />

              <TourismAndHospitality />

              <FinanceAndLending />

              <ManufacturingAndAutomotive />

              <OilAndGas />

              <RetailAndCostomerGoods />

              <ECommerce />

              <TransportAndLogistics />
            </div>

            {/* RIGHT */}
            <div>
              <ForeignWorkerInclusionPayroll />

              <ThaiCorporationsAndFamilyOffices />

              <PLanningANdIPO />

              <OpeningAShop_Franchinse />

              <RealEstate />

              <HealcareAndPhamaceuticals />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usecases;
