import React from "react";

// import logoWallet from "../../assets/icons/Wallets.png";
// import imgData from "../../assets/products/61d3ab15-460e-4591-a210-ceeed5d3c0e4-0.png";
import { CustomButton } from "../CustomButton/CustomButton";
import PayForU from "../../assets/products/payforu.png";

const Ewallets = () => {
  return (
    <div className="">
      <div className="mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl p-2">
        <div>
          <img className="shadow-xl w-screen h-full lg:h-96 object-cover" src={PayForU} />
        </div>

        <div className=" mt-5 lg:mt-10">
          <div className=" flex items-center">
            {/* <img src={logoWallet} className="w-16" /> */}
            <div className="">
              <h3 className="lg:text-4xl text-2xl font-bold text-deep-purple-900">
                E-Wallets
              </h3>
              <h4 className="text-2xl text-orange font-semibold">
                Reconcile as you transact
              </h4>
            </div>
          </div>

          <span className="relative flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
          </span>

          <div className="mt-5">
            <h3 className="text-base lg:text-lg ">
              Make it easier to transact with the ability to top-up, transfer,
              withdraw money across your ecosystem – be it payroll for employees
              or within your close community.
            </h3>

            <h3 className="text-base lg:text-lg mt-10">
              Now you can transfer money to your employees – be it any
              nationality, on a single click. No more paying cash or writing
              cheques, no more approvals on paper, no more tokens to authorize
              payments. A simple elegant way to pay your employees, with an
              ability to send out notifications related to your business. You
              can also whitelabel the wallet to your own brand!
            </h3>

            <h3 className="text-base lg:text-lg mt-10">
              As an individual, ease of paying bills and holding a Thai bank
              account irrespective of your nationality.
            </h3>

            <h3 className="text-base lg:text-lg mt-10">
              Heaven for tourists who are tired of paying heavy bank fees in
              withdrawing cash or paying with their credit cards. Top-up your
              wallet and have the freedom of spending in Thailand – fully
              compliant with Bank of Thailand regulations.
            </h3>
          </div>
        </div>
        <CustomButton
          text="Talk to OUR expert &gt;"
          color="text-white px-8"
          borderColor="border-orange"
          fill="bg-orange"
          padding="p-2 mt-16"
          href="/contact"
        />
      </div>
    </div>
  );
};

export default Ewallets;
