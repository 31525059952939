import React from "react";

const Contact = () => {
  return (
    <div className="">
      <div className="mx-auto h-full sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl p-2 ">
        <div className="md:flex w-full h-full items-center">
          {/* LEFT */}
          <div className="w-full pt-8">
            <div className="pt-6">
              <h2 className="text-2xl font-bold text-orange">Address</h2>
              <h3 className="text-darkBlue text-xl font-bold"> IP PAYMENT SOLUTION CO., LTD. (HEAD OFFICE)</h3>
              <h4 className="text-darkBlue text-lg">
                199 S-OASIS Building, 11th Fl., <br /> 
                Unit No. 1101, 1108, 1109, 1110 <br />
                Vibhavadi-Rangsit Rd., <br /> 
                Chom Phon, <br />
                Chatuchak, Bangkok 10900, Thailand <br />
              </h4>
            </div>
            <div className="pt-6">
              <h2 className="text-2xl font-bold text-orange">Phone</h2>
              <h4 className="text-darkBlue text-lg">Tel. 02 096 9145</h4>
            </div>
            <div className="pt-6">
              <h2 className="text-2xl font-bold text-orange">Email</h2>
              <h4 className="text-darkBlue text-lg">biz.support@ipps.co.th</h4>
            </div>
          </div>
          {/* RIGHT */}
          <div className="w-full pt-16">
            <h4 className="text-lg text-darkBlue">Contact Us</h4>
            <h1 className="text-3xl font-bold text-orange">Write a Message</h1>
            <div className="w-full ">
              <div className="flex p-4">
                <div className="w-full pr-2">
                  <label
                    for="first_name"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="your firstname"
                    required
                  />
                </div>
                <div className="w-full pl-2">
                  <label
                    for="last_name"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="your lastname"
                    required
                  />
                </div>
              </div>
              <div className="w-full p-4">
                <label
                  for="last_name"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="your@email.com"
                  required
                />
              </div>
              <div className="w-full p-4">
                <label
                  for="last_name"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Message
                </label>
                <input
                  type="text"
                  id="last_name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="your message"
                  required
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
