import React from "react";

import imgData_1 from "../../assets/img/outCompany/ipps_cer01.png";
import imgData_2 from "../../assets/img/outCompany/ipps_cer02.png";
import imgData_3 from "../../assets/img/outCompany/ipps_cer03.png";
import imgData_4 from "../../assets/img/outCompany/ipps_cer04.png";

const OurCompany = () => {
  return (
    <div className="">
      <div className="mx-auto h-full sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl p-5 ">
        <div className="w-full items-center text-center text-3xl lg:text-4xl font-bold pt-16 pb-10">
          <h1 className="text-blue">We make your money work more for you</h1>
        </div>

        <div className="w-full text-start text-xl ">
          <p className=" indent-8">
            IP Payment Solutions is a Bank of Thailand licensed payment service
            provider, with licenses for e-money, bill payments, payment gateway
            and e-money transfer. These licenses allow our business to provide
            and operate escrow accounts to customers, manage payments and
            collections on behalf of our customers, and consolidate transfers
            across these accounts.
          </p>

          <p className="mt-5 indent-8">
            We function like a bank regulated by Bank of Thailand, providing
            corporates seamless, data driven transaction transparency with
            instant reconciliation. Our single-minded focus is to help
            organizations gain efficiency and improve decision making multifold
            but giving them real-time visibility of their cash flows and cash
            forecasts – substantially enhancing their working capital positions.
          </p>
        </div>

        <div className="mt-10">
          <div className=" grid grid-cols-2">
            <div>
              <img src={imgData_1} />
            </div>
            <div>
              <img src={imgData_2} />
            </div>
            <div>
              <img src={imgData_3} />
            </div>
            <div>
              <img src={imgData_4} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCompany;
