import React from "react";

import Accounts from "../../assets/icons/Wallets.png";
import imgData from "../../assets/products/virtualaccount.png";
import { CustomButton } from "../CustomButton/CustomButton";

const VirtualAccounts = () => {
  return (
    <div className="">
      <div className="mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl p-2">
        <div>
          <img className="shadow-xl w-full h-full lg:h-96 object-cover object-top" src={imgData} />
        </div>

        <div className=" mt-5 lg:mt-10">
          <div className="">
            {/* <img src={Accounts} className="w-16" /> */}
            <h3 className="lg:text-4xl text-2xl font-bold text-deep-purple-900">
              Virtual Accounts
            </h3>
            <h4 className="text-2xl text-orange font-semibold">
              Gain full control of corporate payments with real-time
              reconciliation.
            </h4>
          </div>

          <span className="relative flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
          </span>

          <div className="mt-5">
            <h3 className="text-base lg:text-lg ">
              Have your entire supply chain on the same payment system, the
              equivalent of bank accounts, but with the ability to have details
              behind every transaction. Who paid, for what, when and how much.
            </h3>

            <h3 className="text-base lg:text-lg mt-10">
              Accounting teams typically end up taking days reconciling invoices
              against payments. Thailand has one of the highest numbers of
              workforce in the accounting and finance teams. That all changes
              with your virtual account. You now have the ability to create
              payment workflows which automatically transfer to a checker from
              maker, and to the approver from a checker. No more tokens, with
              the same amount of security.
            </h3>

            <h3 className="text-base lg:text-lg mt-10">
              Reduce error and fraud in transactions drastically by removing
              paper handoffs, random approvals, back and forth emails, and
              waiting on messengers to get payments signed off.
            </h3>
          </div>
        </div>
        <CustomButton
          text="Talk to OUR expert &gt;"
          color="text-white px-8"
          borderColor="border-orange"
          fill="bg-orange"
          padding="p-2 lg:mt-16"
          href="/contact"
        />
      </div>
    </div>
  );
};

export default VirtualAccounts;
