import React from "react";

// import Logo_Footer from "../../assets/logo/IPPS_Loco_CC_Original-13.png";
// import FooterImg from "../../assets/footer/footer.png";

// import { FaFacebook } from "react-icons/fa";
// import { TbBrandDiscord } from "react-icons/tb";
// import { TbBrandGithub } from "react-icons/tb";
// import { TbBrandX } from "react-icons/tb";
// import { TbBrandTiktok } from "react-icons/tb";

// import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="relative font-sans mt-20">
      <div className="border-t border-gray-200 dark:border-gray-800  bg-blue-gray-900">
        <div className="mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl  px-4 sm:px-6 lg:px-8  py-8 lg:py-16 text-gray-200">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="flex flex-col lg:grid grid-flow-col auto-cols-fr gap-8 xl:col-span-2">
              <div>
                <h3 className="text-sm/6 font-semibold text-gray-50 dark:text-white">
                  Why us
                </h3>
                <ul className="mt-6 space-y-4">
                  <li>
                    <button
                      type="button"
                      className="text-sm relative  dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                    >
                      <a href="/ourCompany">Our Company</a>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="text-sm relative  dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                    >
                      <a href="/ourMission">Our Mission</a>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="text-sm relative  dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                    >
                      <a href="https://v1.ipps.co.th/" target="_blank">
                        Disclaimer
                      </a>
                    </button>
                  </li>
                </ul>
              </div>
              <div data-v-inspector="node_modules/@nuxt/ui-pro/components/footer/FooterColumns.vue:8:7">
                <h3
                  className="text-sm/6 font-semibold text-gray-50 dark:text-white"
                  data-v-inspector="node_modules/@nuxt/ui-pro/components/footer/FooterColumns.vue:9:9"
                >
                  Products
                </h3>
                <ul
                  role="list"
                  className="mt-6 space-y-4"
                  data-v-inspector="node_modules/@nuxt/ui-pro/components/footer/FooterColumns.vue:13:9"
                >
                  <li>
                    <button
                      type="button"
                      className="text-sm relative  dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                    >
                      <a href="/products/e-wallets">e-Wallets</a>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="text-sm relative  dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                    >
                      <a href="/products/virtual-account">Virtual Accounts</a>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="text-sm relative  dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                    >
                      <a href="/products/invoice-QR-Integration">
                        Invoice QR Integration
                      </a>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="text-sm relative  dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                    >
                      <a href="/products/treasury">Treasury</a>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="text-sm relative  dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                    >
                      <a href="/products/B2B">B2B Payments</a>
                    </button>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-sm/6 font-semibold text-gray-50 dark:text-white">
                  Solutions
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  <li>
                    <button
                      type="button"
                      className="text-sm relative  dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                    >
                      <a href="/solutions">Order to Cash</a>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="text-sm relative  dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                    >
                      <a href="/solutions">Record to Report</a>
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="text-sm relative  dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
                    >
                      <a href="/solutions">Cash Management</a>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="mt-10 xl:mt-0"
              data-v-inspector="node_modules/@nuxt/ui-pro/components/footer/FooterColumns.vue:31:5"
            >
              <form>
                <div className="">
                  <div className="">
                    <div className="flex content-center items-center justify-between text-sm">
                      <label className="block font-medium text-gray-100 dark:text-gray-200">
                        Subscribe to IPPS company
                      </label>
                    </div>
                  </div>
                  <div className="relative mt-3">
                    <div className="relative max-w-sm">
                      <input
                        type="email"
                        required=""
                        placeholder="Enter your email"
                        className="relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 form-input placeholder-gray-400 dark:placeholder-gray-500 text-base px-3.5 py-2.5 shadow-sm bg-white dark:bg-gray-900 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 pe-12 rounded-full"
                        autoComplete="off"
                      />
                      <span className="absolute inset-y-0 end-0 flex items-center px-3.5">
                        <button
                          type="submit"
                          className="focus:outline-none disabled:cursor-not-allowed disabled:opacity-75 flex-shrink-0 font-medium rounded-full text-xs gap-x-1.5 px-2.5 py-1.5 shadow-xl text-gray-900 dark:text-gray-900 bg-primary-500 hover:bg-primary-600 disabled:bg-primary-500 dark:bg-primary-400 dark:hover:bg-primary-500 dark:disabled:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 dark:focus-visible:outline-primary-400 inline-flex items-center"
                        >
                          <span className="">Subscribe</span>
                        </button>
                      </span>
                    </div>
                  </div>

                  <div className="relative mt-5">
                    {/* <div className=" flex justify-center text-2xl">
                      <FaFacebook className=" m-2" />
                      <TbBrandDiscord className=" m-2" />
                      <TbBrandGithub className=" m-2" />
                      <TbBrandX className=" m-2" />
                      <TbBrandTiktok className=" m-2" />
                    </div> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-gray-700 dark:border-gray-800 bg-blue-gray-900">
        <div className="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl py-8 lg:py-4 lg:flex lg:items-center lg:justify-between lg:gap-x-3">
          <div className="flex items-center justify-center lg:justify-start lg:flex-1 gap-x-1.5 mt-3 lg:mt-0 lg:order-1">
            <p className="text-gray-100 dark:text-gray-400 ">
              Copyright © 2024. IPPS All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
