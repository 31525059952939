import Treasury from "../../assets/icons/Treasury.png";
import Accounts from "../../assets/icons/Accounts.png";
import Collect from "../../assets/icons/Collect.png";
import E_Doc from "../../assets/icons/E-Doc.png";
import E_Tax from "../../assets/icons/E-Tax.png";
import Wallets from "../../assets/icons/Wallets.png";
import B2B_logo from "../../assets/icons/B2B.png";

import { Link } from "react-router-dom";

const LinkComponants = "text-darkBlue bg-white text-sm w-[12vw]";
const LinkLi = "hover:bg-darkBlue hover:text-white flex ";
const LinkImg = "w-12";
const LinkA = "flex justify-center items-center p-4";

export const WhyUs = () => {
  return (
    <div className={LinkComponants}>
      <li className={LinkLi}>
        <Link to="/ourCompany">
          <a className={LinkA}>Our company</a>
        </Link>
      </li>

      <li className={LinkLi}>
        <Link to="/ourMission">
          <a className={LinkA}>Our Mission</a>
        </Link>
      </li>
    </div>
  );
};

export const Products = () => {
  return (
    <div className={LinkComponants}>
      <li className={`${LinkLi} py-2`}>
        <Link to="/products/treasury">
          <a className={LinkA}>
            <img src={Treasury} alt="treasury" className={LinkImg} />
            Treasury
          </a>
        </Link>
      </li>
      <li className={`${LinkLi} py-2`}>
        <Link to="/products/virtual-account">
          <a className={LinkA}>
            <img src={Accounts} alt="accounts" className={LinkImg} />
            Virtual Accounts
          </a>
        </Link>
      </li>
      <li className={`${LinkLi} py-2`}>
        <Link to="/products/e-wallets">
          <a className={LinkA}>
            <img src={Wallets} alt="wallets" className={LinkImg} />
            E-Wallets
          </a>
        </Link>
      </li>
      <li className={`${LinkLi} py-2`}>
        <Link to="/products/invoice-QR-Integration">
          <a className={LinkA}>
            <img src={E_Doc} alt="edoc" className={LinkImg} />
            Invoice QR Integration
          </a>
        </Link>
      </li>
      <li className={`${LinkLi} py-2`}>
        <Link to="/products/b2b">
          <a className={LinkA}>
            <img src={B2B_logo} alt="b2b" className={LinkImg} />
            B2B payments
          </a>
        </Link>
      </li>
    </div>
  );
};

export const Solutions = () => {
  return (
    <div className={LinkComponants}>
      <li className={`${LinkLi}`}>
        <Link to="/solutions">
          <a className={LinkA}>B2B Payments</a>
        </Link>
      </li>
      <li className={`${LinkLi}`}>
        <Link to="/solutions">
          <a className={LinkA}>Order to Cash</a>
        </Link>
      </li>
      <li className={`${LinkLi}`}>
        <Link to="/solutions">
          <a className={LinkA}>Record to Report</a>
        </Link>
      </li>
      <li className={`${LinkLi}`}>
        <Link to="/solutions">
          <a className={LinkA}>Cash Management</a>
        </Link>
      </li>
      <li className={`${LinkLi}`}>
        <Link to="/solutions">
          <a className={LinkA}>Account Payables</a>
        </Link>
      </li>
    </div>
  );
};

export const Resources = () => {
  return (
    <div className={LinkComponants}>
      <li className={`${LinkLi}`}>
        <Link to="/usecase">
          <a className={LinkA}>Use cases</a>
        </Link>
      </li>
    </div>
  );
};

export const ContactUs = () => {
  return (
    <div className={LinkComponants}>
      <li className={`${LinkLi}`}>
        <Link to="/contact">
          <a className={LinkA}>Location</a>
        </Link>
      </li>
      {/* <li className={`${LinkLi}`}>
        <Link to="/contact">
          <a className={LinkA}>
            Careers
          </a>
        </Link>
      </li> */}
    </div>
  );
};
