import React from "react";

// import logoWallet from "../../assets/icons/Wallets.png";
import Order2Cash from "../../assets/solutions/order2cash.png"
import B2B from "../../assets/solutions/B2B.png"
import Record2Report from '../../assets/solutions/record2report.png'
import CashManagement from '../../assets/solutions/CashMenagement.png'
import AccountPayable from '../../assets/solutions/AccountPayable.png'

const Solutions = () => {
  return (
    <div className="">
      <div className="mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl p-2">

        <div className=" mt-5 lg:mt-10">
          <div className=" flex  justify-centers py-1 lg:py-4">
            <h3 className="lg:text-2xl text-2xl font-bold text-deep-purple-900 text-center uppercase">
              Solutions
            </h3>
          </div>

          <div className=" grid lg:grid-cols-2 grid-cols-1 gap-5 mt-10">
            <div className=" flex items-center">
              <div>
                <div>
                  <h3 className="text-base lg:text-2xl text-deep-purple-900 font-bold">
                    B2B Payments
                  </h3>
                </div>
                <h3 className="text-base lg:text-xl mt-2">
                  <b className="text-orange">Payment Gateway</b> – Integrate payments with ease on your
                  website, your POS device or your EDC devices, with some of the
                  best rates in the market. Secure electronic payment
                  processing. We also provide a range of POS and EDC devices.
                </h3>

                <h3 className="text-base lg:text-xl mt-3">
                  <b className="text-orange">Merchant Management System</b> – Manage payments,
                  settlements, reconciliation at the most competitive rates
                  available in Thailand. Have full visibility of all
                  transactions, manage refunds and settlements with ease.
                </h3>

                <h3 className="text-base lg:text-xl mt-3">
                  <b className="text-orange">Virtual Accounts</b> – Pay directly to other Virtual
                  Accounts within IPPS provided ecosystem, or to other banks on
                  a single click
                </h3>
              </div>
            </div>

            <div className=" flex items-center justify-center">
              <img src={B2B} className="object-cover round-md rounded-lg" />
            </div>
          </div>
        </div>

        <div className=" mt-5 lg:mt-16">
          <div className=" grid lg:grid-cols-2 grid-cols-1 gap-5 mt-5">
            <div className=" flex items-center justify-center">
              <img src={Order2Cash} className="object-cover round-md rounded-lg" />
            </div>
            <div className=" flex items-center">
              <div>
                <div>
                  <h3 className="text-base lg:text-2xl text-deep-purple-900 font-bold">
                    Order to Cash
                  </h3>
                  <h4 className="text-sm">
                    Reduce leverage, Collect efficiently
                  </h4>
                </div>
                <h3 className="text-base lg:text-xl mt-2">
                  <b className="text-orange">Faster collections and reconciliation at lower cost</b>–
                  Send invoices with QR codes or bank transfer links to ensure
                  every collection comes with a payment reference and is
                  accounted for against invoices raised. Reduce collection costs
                  and carbon footprint by 30% from day 1.
                </h3>

                <h3 className="text-base lg:text-xl mt-3">
                  <b className="text-orange">Credit management</b> – Manage credit with customers better
                  to ensure they pay in time, or charge interest in agreement
                  with them for delayed payments. Maybe propose early payments
                  in lieu of discounts if you need cash, rather than taking bank
                  overdrafts. Whichever way, our partner solutions help you to
                  record transactions effectively, while creating general ledger
                  entries as you transact, keeping your books clean
                  effortlessly. Seamlessly manage credit across your ecosystem.
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className=" mt-5 lg:mt-16">
          <div className=" grid lg:grid-cols-2 grid-cols-1 gap-5 mt-5">
            <div className=" flex items-center">
              <div>
                <div>
                  <h3 className="text-base lg:text-2xl text-deep-purple-900 font-bold">
                    Record to Report
                  </h3>
                  <h4 className="text-sm">
                    close your books in days instead of weeks.
                  </h4>
                </div>
                <h3 className="text-base lg:text-xl mt-2">
                  <b className="text-orange">Financial close management </b>– Total account
                  reconciliation with automated GL reporting to ensure a smooth
                  month-end close. Reduce days to close by at-least 50%.
                </h3>

                <h3 className="text-base lg:text-xl mt-3">
                  <b className="text-orange">Real-time reconciliation</b> – Payments made with
                  references help you ensure you reconcile real-time. Payments
                  on our system give you details on who paid for what, and when.
                  You can input invoice details in the payment reference, or any
                  other reference required for you to reconcile.
                </h3>

                <h3 className="text-base lg:text-xl mt-3">
                  <b className="text-orange">Anomaly management</b> – Payments made with Validate
                  payments before accepting them, AI/ML driven rule based
                  checks. The system lists any anomalies that sit outside the
                  rule based parameters. Reduce fraud by 90%.
                </h3>
              </div>
            </div>
            <div className=" flex items-center justify-center">
              <img src={Record2Report} className="object-cover round-md rounded-lg" />
            </div>
          </div>
        </div>

        <div className=" mt-5 lg:mt-16">
          <div className=" grid lg:grid-cols-2 grid-cols-1 gap-5 mt-5">
            <div className=" flex items-center justify-center">
              <img src={CashManagement} className="object-cover round-md rounded-lg" />
            </div>
            <div className=" flex items-center">
              <div>
                <div>
                  <h3 className="text-base lg:text-2xl text-deep-purple-900 font-bold">
                    Cash Management
                  </h3>
                  <h4 className="text-sm">
                    Manage your working capital like a pro.
                  </h4>
                </div>
                <h3 className="text-base lg:text-xl mt-2">
                  <b className="text-orange">To manage cash effectively</b> – financial controllers need
                  real-time visibility from of cash from all quarters of your
                  business. This means a pulse check across subsidiaries, across
                  departments, across banks, payables and receivables every
                  single day. Our solutions make this data available at your
                  fingertips, customized to your business workflows.
                </h3>

                <h3 className="text-base lg:text-xl mt-3">
                  <b className="text-orange">Completely automated daily cash positions</b> – Optimize your debt,
                  investments and multi-currency accounts on a single click.
                  Hands-free cash reconciliation to increase productivity by 70%
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className=" mt-5 lg:mt-16">
          <div className=" grid lg:grid-cols-2 grid-cols-1 gap-5 mt-5">
            <div className=" flex items-center">
              <div>
                <h3 className="text-base lg:text-2xl text-deep-purple-900 font-bold">
                  Account Payables
                </h3>
                <h3 className="text-base lg:text-xl mt-2">
                  De-centralise payables with defined authority limits across
                  functions while still retaining full control and transparency
                  with our parent-child accounts. Never miss a payment with
                  payments setup with the system, integrated with your ERP. Pay
                  to any bank from a single portal.
                </h3>
              </div>
            </div>

            <div className=" flex items-center justify-center">
              <img src={AccountPayable} className="object-cover round-md rounded-lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
