import React from "react";

const OurMission = () => {
  return (
    <div className="">
      <div className="mx-auto h-full sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl p-2 ">
        <div className="w-full items-center text-center text-3xl font-bold p-14 ">
          <h1 className="text-blue">
            Automate supply chain finance and digitize organizational AR and AP
            processes to support the CFO in focusing on business growth
            strategy.
          </h1>
        </div>

          <p className="ml-12 text-2xl font-bold text-orange ">
            Our solutions drive the following benefits in organizations of every
            size and complexity.
          </p>
        <div className="w-full text-start text-lg ">
          <ul className="ml-16 gab-2 list-disc">
            <li>Simplify AR and reconciliation.</li>
            <li>
              Optimize working capital – collect first from end customer,
              disburse later.
            </li>
            <li>
              Get a single view of cash – Manage cash and forecast better.
            </li>
            <li>
              Use cash wisely – 100% cash visibility for better debt and
              investment planning.
            </li>
            <li>
              Simplified AP – Entire payment lifecycle workflow for ALL BANKS in
              a single solution.
            </li>
            <li>
              Reduce bank fees with our closed loop ecosystem with partner
              banks.
            </li>
            <li>
              Reduce operational overheads – Automate GL, approvals, reports. No
              cheques, no messengers.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
