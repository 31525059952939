import React, { useState } from "react";
import { AiOutlineGlobal } from "react-icons/ai";
import { AnimatePresence, motion } from "framer-motion";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosArrowForward } from "react-icons/io";

import { Link } from "react-router-dom";

import IPPS from "../../assets/logo/NavLogo.png";

import { WhyUs, Products, Solutions, Resources, ContactUs } from "./Links";

const FlyoutLink = ({ children, href, FlyoutContent, IsOpen }) => {
  const [open, setOpen] = useState(false);
  const showFlyout = FlyoutContent && open;

  return (
    <div>
      {/* W H LINKS  */}
      <div
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        className="relative hover:bg-darkBlue hover:text-white hover:underline uppercase font-bold flex items-center h-[6vh] px-[1vw] m-auto"
      >
        <a href={href} className="relative">
          {children}
          <span
            style={{
              transform: showFlyout ? "scaleX(1)" : "scaleX(0)",
            }}
            className="absolute duration-300 ease-out flex m-auto"
          />
        </a>
        <AnimatePresence>
          {showFlyout && (
            <motion.div
              initial={{ opacity: 0, y: 15 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 15 }}
              style={{ translateX: "-50%" }}
              transition={{ duration: 0.3, ease: "easeOut" }}
              // DISTANCE BETWEEN NAV
              className="absolute z-20 left-[6vw] top-[6vh] w-[12vw] bg-white text-white "
            >
              <div className="absolute top-6 left-0 right-0 h-6 bg-transparent" />
              <FlyoutContent />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

const MobileNave = () => {
  const [whyus, setWhyus] = useState(false);
  const [products, setProducts] = useState(false);
  const [solutions, setsetSolutionshyus] = useState(false);
  const [resources, setResources] = useState(false);
  const [contact, setContact] = useState(false);

  const LinksMobileLi =
    "flex h-[4vh] px-4 py-2 text-center tems-center justify-between";

  const LinksSubMobile = "absolute z-70 w-[96vw] md:w-[98vw] bg-blue mr-4 ";

  const LinksLi = "py-2";

  return (
    <div className="lg:hidden left-0 w-full bg-darkBlue text-white font-bold uppercase ">
      <ul>
        <li className={LinksMobileLi} onClick={(e) => setWhyus(!whyus)}>
          Why us?
          <IoIosArrowForward className={whyus ? "mr-4 rotate-90" : "mr-4"} />
          <div className={whyus ? `${LinksSubMobile} top-10 ` : "hidden"}>
            <li className={LinksLi}>
              <Link to="/ourCompany">
                <a>Our company</a>
              </Link>
            </li>
            <li className={LinksLi}>
              <Link to="/ourMission">
                <a>Our mission</a>
              </Link>
            </li>
          </div>
        </li>
        <li className={LinksMobileLi} onClick={(e) => setProducts(!products)}>
          products
          <IoIosArrowForward className={products ? "mr-4 rotate-90" : "mr-4"} />
          <div className={products ? `${LinksSubMobile} top-20 ` : "hidden"}>
            <li className={LinksLi}>
              <Link to="/products/treasury">
                <a>Treasury</a>
              </Link>
            </li>
            <li className={LinksLi}>
              <Link to="/products/virtual-account">
                <a>Virtual Accounts</a>
              </Link>
            </li>
            <li className={LinksLi}>
              <Link to="/products/e-wallets">
                <a>E-Wallets</a>
              </Link>
            </li>
            <li className={LinksLi}>
              <Link to="/products/invoice-QR-Integration">
                <a>Invoice-QR-Integration</a>
              </Link>
            </li>
            <li className={LinksLi}>
              <Link to="/products/B2B">
                <a>B2B payments </a>
              </Link>
            </li>
          </div>
        </li>
        <li
          className={LinksMobileLi}
          onClick={(e) => setsetSolutionshyus(!solutions)}
        >
          solutions
          <IoIosArrowForward
            className={solutions ? "mr-4 rotate-90" : "mr-4"}
          />
          <div
            className={solutions ? `${LinksSubMobile} top-[12vh] ` : "hidden"}
          >
            <li className={LinksLi}>
              <a href="/solutions">B2B Payments</a>
            </li>
            {/* <li className={LinksLi}>
              <a href="/solutions">B2B Payments</a>
            </li> */}
            <li className={LinksLi}>
              <a href="/solutions">Order to Cash</a>
            </li>
            <li className={LinksLi}>
              <a href="/solutions">Record to Report</a>
            </li>
            <li className={LinksLi}>
              <a href="/solutions">Cash Management</a>
            </li>
            <li className={LinksLi}>
              <a href="/solutions">Payment Gateway</a>
            </li>
            <li className={LinksLi}>
              <a href="/solutions">Revenue Recoognition</a>
            </li>
          </div>
        </li>
        <li className={LinksMobileLi} onClick={(e) => setResources(!resources)}>
          resources
          <IoIosArrowForward
            className={resources ? "mr-4 rotate-90" : "mr-4"}
          />
          <div
            className={resources ? `${LinksSubMobile} top-[16vh] ` : "hidden"}
          >
            <li className={LinksLi}>
              <Link to="/usecase">
                <a>User cases</a>
              </Link>
            </li>
          </div>
        </li>
        <li className={LinksMobileLi} onClick={(e) => setContact(!contact)}>
          contact us
          <IoIosArrowForward className={contact ? "mr-4 rotate-90" : "mr-4"} />
          <div className={contact ? `${LinksSubMobile} top-[20vh] ` : "hidden"}>
            <li className={LinksLi}>
              <Link to="/contact">
                <a>Location</a>
              </Link>
            </li>
            {/* <li className={LinksLi}>
              <Link to="/careers">
                <a>Careers</a>
              </Link>
            </li> */}
          </div>
        </li>
      </ul>
    </div>
  );
};

const NavBar = () => {
  const [IsOpen, setIsOpen] = useState(false);

  const OpenMenu = () => {
    setIsOpen(!IsOpen);
  };

  return (
    <nav className="z-100 mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl m-auto px-4">
      <div className="flex h-[6vh] mx-2 lg:mx-0 justify-between ">
        {/* IMAGE */}
        <div className="flex">
          <Link to="/" className="flex">
            <img src={IPPS} alt="IPPS" className="h-[4vh] m-auto" />
          </Link>
        </div>

        {/* LINKS */}
        <div className="">
          <ul className="hidden lg:flex w-full gap-4 m-auto">
            <FlyoutLink FlyoutContent={WhyUs}>why us?</FlyoutLink>
            <FlyoutLink FlyoutContent={Products}>products</FlyoutLink>
            <FlyoutLink FlyoutContent={Solutions}>solutions</FlyoutLink>
            <FlyoutLink FlyoutContent={Resources}>resources</FlyoutLink>
            <FlyoutLink FlyoutContent={ContactUs}>contact us</FlyoutLink>

            <div className="flex m-auto px-0 lg:px-16 font-bold items-center">
              <AiOutlineGlobal />
              <div className="uppercase">TH/EN</div>
            </div>
          </ul>

          <RxHamburgerMenu
            size={30}
            className="lg:hidden flex m-auto w-full h-full"
            onClick={OpenMenu}
          />

          {IsOpen && (
            <div className="absolute z-50 w-full left-0">
              <MobileNave />
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
