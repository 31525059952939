import React from 'react'

import Banner from '../Banner/Banner'
import MainService from '../MainService/MainServices'


const Home = () => {
  return (
    <div>
      <Banner />
      <MainService />
    </div>
  )
}

export default Home
