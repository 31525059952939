import { useState } from "react";
import {
  ProductCard_1,
  ProductCard_2,
  ProductCard_4,
  ProductCard_5,
  SolutionCard_1,
  SolutionCard_2,
  SolutionCard_3,
  SolutionCard_4,
  SolutionCard_5,
} from "./ServiceDetails";

import { Link } from "react-router-dom";

export const CustomButton = ({
  text,
  color,
  bgColor,
  borderColor,
  fill,
  padding,
  href,
}) => {
  return (
    <Link to={href}>
      <button
        className={`${color} ${bgColor} ${fill} ${borderColor} ${padding} text-sm md:uppercase border-2 px-4 mx-4`}
      >
        {text}
      </button>
    </Link>
  );
};

const ProductsCard = () => {
  return (
    <div className="m-0 md:m-10 ">
      <div className="mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl px-4">
        <div className="grid items-center content-center grid-cols-2 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 m-auto">
          <ProductCard_1 />
          <ProductCard_2 />
          <ProductCard_4 />
          <ProductCard_5 />
        </div>
      </div>
    </div>
  );
};

const SolutionsCard = () => {
  return (
    <div className="m-0 md:m-10">
      <div className="mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl px-4">
        <div className="grid items-center content-center grid-cols-2 xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4 m-auto">
          <SolutionCard_1 />
          <SolutionCard_2 />
          <SolutionCard_3 />
          <SolutionCard_4 />
          <SolutionCard_5 />
        </div>
      </div>
    </div>
  );
};

const MainService = () => {
  const [toggle, setToggle] = useState(true);

  const ClickProduct = () => {
    setToggle(!toggle);
  };

  const toggleDecorationsOn =
    "mt-8 mb-4 mx-4 text-2xl font-bold text-darkBlue uppercase hover:bg-darkBlue hover:text-white p-4 hover:rounded-md  border-b-4 border-darkBlue";
  const toggleDecorationsOff =
    "mt-8 mb-4 mx-4 text-2xl font-bold text-darkBlue uppercase hover:bg-darkBlue hover:text-white p-4 rounded-md ";

  return (
    <div className=" my-16 px-4">
      <div className="w-full text-center m-auto pb-4 lg:px-0">
        <div className="mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl px-4">
          {/* HEADER */}
          <div className="flex m-auto justify-center ">
            <p className="font-bold text-3xl md:4xl lg:5xl xl:text-4xl text-blue text-start ">
              One stop solution for all your corporate payment, collection,
              working capital and cash management requirements.
            </p>
          </div>

          {/* SUBTEXT*/}
          <div className="m-auto justify-start mt-12  text-2xl text-red-500">
            <p className="text-start">
              Trusted by multiple companies to manage and automate transactions
              on their behalf, returning substantial time efficiencies and cost
              savings.
            </p>

            <p className="text-start">
              Our solutions reduce the cost of transactions, eliminate fraud,
              and let you focus on your strategic operations at peace.
            </p>
          </div>
        </div>

        {/* Card */}
        <div className=" h-full">
          <div className="flex justify-center m-auto">
            <button
              className={
                toggle ? `${toggleDecorationsOn} ` : `${toggleDecorationsOff} `
              }
              onClick={ClickProduct}
            >
              Products
            </button>
            <button
              className={
                toggle ? `${toggleDecorationsOff} ` : `${toggleDecorationsOn} `
              }
              onClick={ClickProduct}
            >
              Solutions
            </button>
          </div>
          {toggle ? <ProductsCard /> : <SolutionsCard />}
        </div>
      </div>
      <div className="mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl px-4 mt-10">
        <div className="grid grid-cols-2">
          <div>
            <CustomButton
              text="Success case per industry"
              color="text-white"
              borderColor="border-orange uppercase"
              fill="bg-orange"
              padding="p-2"
              href="usecase"
            />
          </div>
          <div className="flex justify-end ">
            <CustomButton
              className="rounded-xl"
              text="Talk to our expert"
              color="text-orange uppercase"
              borderColor="border-orange"
              padding="p-2"
              href="contact"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainService;
