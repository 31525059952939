import React from "react";

import Invoice from "../../assets/products/invoiceQrintegration.png"
import { CustomButton } from "../CustomButton/CustomButton";

const InvoiceQR_Integration = () => {
  return (
    <div className="">
      <div className="mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl p-2">
        <div>
          <img className="shadow-xl w-screen h-full lg:h-96 object-cover" src={Invoice} />
        </div>

        <div className=" mt-5 lg:mt-10">
          <div className=" flex items-center">
            {/* <img src={Accounts} className="w-16" /> */}
            <div>
              <h3 className="lg:text-4xl text-2xl font-bold text-deep-purple-900">
                Invoice QR Integration
              </h3>
              <h4 className="text-2xl text-orange font-semibold">
                Invoice Payments, Instant Invoice Reconciliation
              </h4>
            </div>
          </div>

          <span className="relative flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
          </span>

          <div className="mt-5">
            <h3 className="text-base lg:text-lg ">
              How do you send invoices today? With bank account details? And
              when do you know when you received the money owed? When accounts
              team finally reconciles all accounts receivables? And what if you
              realized you didn’t receive the money after all? Then you start
              chasing the customers for payments. Imagine the amount of time,
              effort, and cash you lost out on – which you could save by one
              easy integration – embedded QR or payment link on the invoice.
            </h3>

            <h3 className="text-base lg:text-lg mt-10">
              What is so great about it? The fact that such payments then come
              with a reference. Which means you know who paid and who didn’t,
              instantaneously. Take control of your cash today.
            </h3>
          </div>
        </div>
        <CustomButton
          text="Talk to OUR expert &gt;"
          color="text-white px-8"
          borderColor="border-orange"
          fill="bg-orange"
          padding="p-2 mt-16"
          href="/contact"
        />
      </div>
    </div>
  );
};

export default InvoiceQR_Integration;
