import "./App.css";

import NavBar from "./components/NavBar/NavBar.js";
import Home from "./components/Home/Home.js";

import OurCompany from "./components/OurCompany/OurCompany.js";
import OurMission from "./components/OurMission/ourMission.js";



import Contact from "./components/Contact/Contact.js";
import Treasury from "./components/Products/Treasury.js";
import VirtualAccounts from "./components/Products/VirtualAccounts.js";
import Ewallets from "./components/Products/Ewallets.js";
import InvoiceQR_Integration from "./components/Products/InvoiceQR_Integration.js";
import Solutions from "./components/Solutions/Solutions.js";
import Usecases from "./components/Usecases/Usecases.js";

import Footer from "./components/Footer/Footer.js";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import B2B_Page from "./components/Products/B2B.js";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* NAVBAR */}
        <NavBar />
        {/* ROUTERS */}
        <Routes>
          {/* HOME */}
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          {/* WHY US? */}
          <Route path="/ourCompany" element={<OurCompany />} />
          <Route path="/ourMission" element={<OurMission />} />

          {/* PRODUCTS */}
          <Route path="/products/e-wallets" element={<Ewallets />} />
          <Route
            path="/products/virtual-account"
            element={<VirtualAccounts />}
          />
          <Route
            path="/products/invoice-QR-Integration"
            element={<InvoiceQR_Integration />}
          />
          <Route path="/products/treasury" element={<Treasury />} />
          <Route path="/products/b2b" element={<B2B_Page />} />

          {/* SOLUTIONS */}
          <Route path="/solutions" element={<Solutions />} />
          {/* USECASES */}
          <Route path="/usecase" element={<Usecases />} />

          
          {/* <Route path="/" element={<Contact />} /> */}
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
