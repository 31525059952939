import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import IPPBanner from "../../assets/bandner/IPPSWebsiteBanner.png";

export const CustomButton = ({
  text,
  color,
  bgColor,
  borderColor,
  fill,
  padding,
  href,
}) => {
  return (
    <Link to={href}>
      <button
        className={`${color} ${bgColor} ${fill} ${borderColor} ${padding} md:text-md text-md font-bold border-2`}
      >
        {text}
      </button>
    </Link>
  );
};

const Banner = () => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 6000,
    dotsClass: "slick-dots",
  };

  return (
    <div className="bg-darkBlue bg-gradient-to-r from-indigo-500">
      <div className="relative overflow-hidden mx-auto sm:max-w-3xl lg:max-w-screen-2xl 2xl:max-w-screen-2xl px-4">
        {/* <img src={IPPBanner} className="absolute hidden lg:flex right-0 bottom-0 object-fill h-96" /> */}
        <div className="hidden lg:flex">
          <div className="absolute z-30 top-[80%] flex justify-center">
            <CustomButton
              // text="Talk To Our Expert &gt;"
              text="Talk To Our Expert"
              color="text-white"
              borderColor="border-orange"
              fill="bg-orange"
              padding="md:p-2"
              href="/contact"
            />

            <CustomButton
              // text="Talk To Our Expert &gt;"
              text="Products"
              color="text-white"
              borderColor="border-orange"
              fill="hover:bg-orange"
              padding="md:p-2 xl:px-8 mx-4"
              href="/products/treasury"
            />
          </div>
        </div>

        <img src={IPPBanner} className="absolute z-10 hidden lg:flex right-0 bottom-0 object-fill h-96" />


        <div className="z-100 w-screen overflow-hidden">
          <div className="grid grid-cols-1 lg:grid-cols-2 h-96">
            <Slider
              {...settings}
              className="h-ful w-full max-w-[650px] flex items-center justify-end text-white "
            >
              <h3 className="text-3xl lg:text-3xl text-left">
                Never worry about cash flow again
              </h3>
              <h3 className="text-3xl lg:text-3xl text-left">
                Make payments seamlessly
              </h3>
              <h3 className="text-3xl lg:text-3xl text-left">
                Automate supply chain payments
              </h3>

              <h3 className="text-3xl lg:text-3xl text-left">
                Reconcile as you transact
              </h3>

              <h3 className="text-3xl lg:text-3xl text-left">
                Master corporate financial health
              </h3>

              <h3 className="text-3xl lg:text-3xl text-left">
                Trade with ethical finance
              </h3>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
